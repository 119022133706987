import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';
import { useFormState } from 'react-final-form';
import ButtonField from '@soosap/sushi/Form/ButtonField';

import Container from 'atoms/Container';
import Title from 'atoms/Title';

import styles from './Selection.module.scss';

export const GIFTCARD_SELECTION_QUERY = graphql`
  query GiftCardSelectionQuery {
    giftcard3000: file(
      relativePath: { eq: "giftcard/thamarai-gutschein-3000.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    giftcard5000: file(
      relativePath: { eq: "giftcard/thamarai-gutschein-5000.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    giftcard10000: file(
      relativePath: { eq: "giftcard/thamarai-gutschein-10000.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  giftcard3000: {
    childImageSharp: GatsbyImageFluidProps;
  };
  giftcard5000: {
    childImageSharp: GatsbyImageFluidProps;
  };
  giftcard10000: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

export interface Props {}

const Selection: React.FC<Props> = () => {
  const giftcards = useStaticQuery<Data>(GIFTCARD_SELECTION_QUERY);
  const { values } = useFormState({ subscription: { values: true } });

  return (
    <>
      <Title>Auswahl</Title>
      <Container.Inner>
        <div className={styles[`Selection`]}>
          <div className={styles[`Selection__giftcard`]}>
            <Img
              className={styles[`Selection__image`]}
              {...giftcards[`giftcard${values['nominalAmount']}`]
                .childImageSharp}
            />
          </div>
          <ButtonField name="nominalAmount">
            <ButtonField.Option label="30 EUR" value={3000} />
            <ButtonField.Option label="50 EUR" value={5000} />
            <ButtonField.Option label="100 EUR" value={10000} />
          </ButtonField>
        </div>
      </Container.Inner>
    </>
  );
};

export default Selection;
