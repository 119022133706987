import React from 'react';
import { useFormState } from 'react-final-form';
import Button, { ButtonSize } from '@soosap/sushi/Button';
import Spinner, { SpinnerTheme } from '@soosap/sushi/Spinner';

import Container from 'atoms/Container';

import styles from './CallToAction.module.scss';

export interface Props {}

const CallToAction: React.FC<Props> = () => {
  const { invalid, submitting, values } = useFormState({
    subscription: {
      invalid: true,
      submitting: true,
      values: true,
    },
  });

  return (
    <Container.Inner>
      <div className={styles[`CallToAction`]}>
        <Button
          primary
          size={ButtonSize.SMALL}
          disabled={invalid || submitting}
          type="submit"
          onMouseDown={(e) => e.preventDefault()}
        >
          {submitting ? (
            <>
              <Spinner
                className={styles[`CallToAction__spinner`]}
                theme={SpinnerTheme.LIGHT}
              />
              <span>In Bearbeitung</span>
            </>
          ) : (
            `${(values['nominalAmount'] / 100).toFixed(0)}€ Gutschein ${
              values['paymentMethod'] === 'cash' ? 'bestellen' : 'bezahlen'
            }`
          )}
        </Button>
      </div>
    </Container.Inner>
  );
};

export default CallToAction;
