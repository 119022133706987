import React from 'react';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';

import styles from '../Payment.module.scss';

export interface Props {}

const PaymentCash: React.FC<Props> = () => {
  return (
    <FieldSet
      className={styles[`Payment__fieldset`]}
      title="Bezahlung im Restaurant"
    >
      <TextField
        name="giverFullName"
        label="Ihr Name"
        placeholder="z.B. Dorothea Köster"
        required
        requiredError="Pflichtfeld"
      />
      <TextField
        name="giverPhoneNumber"
        label="Ihre Telefonnummer"
        placeholder="z.B. 07131 405 11 70"
        required
        requiredError="Pflichtfeld"
      />
    </FieldSet>
  );
};

export default PaymentCash;
