import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import GiftCard from 'organisms/GiftCard';

import 'styles/pages/gutschein.scss';

export const GIFTCARD_QUERY = graphql`
  query GiftCardQuery {
    logo: file(relativePath: { eq: "logos/thamarai-gutschein-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
}

export interface Props {}

const GutscheinPage: React.FC<Props> = () => {
  const { logo } = useStaticQuery<Data>(GIFTCARD_QUERY);

  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/gutschein-verschenken"
          canonical="/gutschein-verschenken"
          lang="de-DE"
          title="Gutschein"
          description="Ein Gutschein aus dem Hause THAMARAI ist eine tolle Geschenkidee um Ihren Liebsten eine Freude zu machen und Ihnen die feine tamilische Küche zu empfehlen."
        />
      )}
      header={() => (
        <Layout.Header
          title="THAMARAI Gutschein"
          subtitle="Schenken Sie Ihren Freunden eine kulinarische Reise in die tamilische Küche aus Sri Lanka und Südindien"
          abstract="Ein Gutschein aus dem Hause THAMARAI ist eine tolle Geschenkidee um Ihren Liebsten eine Freude zu machen und Ihnen die köstlichen Speisen der tamilisch indischen Küche zu empfehlen."
          image={logo.childImageSharp}
          alt="THAMARAI Gutschein Exklusives Logo"
        />
      )}
    >
      <div className={`GutscheinPage`}>
        <GiftCard />
      </div>
    </Layout>
  );
};

export default GutscheinPage;
