import React from 'react';
import { useFormState } from 'react-final-form';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import RadioField from '@soosap/sushi/Form/RadioField';
import EmailField from '@soosap/sushi/Form/EmailField';
import FieldSet from '@soosap/sushi/FieldSet';

import { ShippingMethod } from 'server/src/app/giftcard/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';

import styles from './Shipping.module.scss';

export interface Props {}

const Shipping: React.FC<Props> = () => {
  const { values } = useFormState({ subscription: { values: true } });

  return (
    <>
      <Title>Übergabe</Title>
      <Container.Inner>
        <div className={styles[`Shipping`]}>
          <div className={styles[`Shipping__options`]}>
            <RadioField name="shippingMethod" horizontal>
              <RadioField.Option value="email" label="E-Mail" />
              <RadioField.Option value="mail" label="Post" />
              <RadioField.Option value="pickup" label="Abholung" />
            </RadioField>
          </div>

          <FieldSet
            title="Gutscheinversand per E-Mail"
            className={`${styles[`Shipping__fieldset`]} ${
              styles[`Shipping__fieldset--email`]
            } ${
              values['shippingMethod'] === ShippingMethod.EMAIL
                ? styles['Shipping__fieldset--active']
                : ''
            }`}
          >
            {values['shippingMethod'] === ShippingMethod.EMAIL && (
              <>
                <TextField
                  name="recipientFullName"
                  label="Emfänger Vor- und Nachname"
                  required
                  placeholder="z.B. Demian Köster"
                />
                <EmailField
                  name="recipientEmail"
                  required
                  label="Empfänger E-Mail Adresse"
                  placeholder="z.B. geburtstagskind@web.de"
                />
              </>
            )}
            <TextAreaField
              label="Widmung"
              name="dedication"
              placeholder="Ihre persönliche Widmung an den Empfänger des Gutscheins (optional)."
              maxLength={240}
              maxLengthTemplate="Noch XXX Zeichen verfügbar"
            />
          </FieldSet>
          <FieldSet
            title="Gutscheinversand per Post"
            className={`${styles[`Shipping__fieldset`]} ${
              styles[`Shipping__fieldset--postal`]
            } ${
              values['shippingMethod'] === ShippingMethod.MAIL
                ? styles['Shipping__fieldset--active']
                : ''
            }`}
          >
            {values['shippingMethod'] === ShippingMethod.MAIL && (
              <>
                <TextField
                  className={`${styles[`Shipping__field`]} ${
                    styles[`Shipping__field--name`]
                  }`}
                  name="recipientFullName"
                  label="Emfänger Vor- und Nachname"
                  placeholder="z.B. Demian Köster"
                  required
                />
                <TextField
                  className={`${styles[`Shipping__field`]} ${
                    styles[`Shipping__field--address`]
                  }`}
                  name="recipientAddress"
                  label="Adresse"
                  placeholder="z.B. Horkheimer Straße 29"
                  required
                />
                <TextField
                  className={`${styles[`Shipping__field`]} ${
                    styles[`Shipping__field--postcode`]
                  }`}
                  name="recipientPostCode"
                  label="PLZ"
                  placeholder="z.B. 74081"
                  required
                />
                <TextField
                  className={`${styles[`Shipping__field`]} ${
                    styles[`Shipping__field--city`]
                  }`}
                  name="recipientCity"
                  label="Wohnort"
                  placeholder="z.B. Heilbronn"
                  required
                />
              </>
            )}
            <TextAreaField
              className={`${styles[`Shipping__field`]} ${
                styles[`Shipping__field--dedication`]
              }`}
              label="Widmung"
              name="dedication"
              placeholder="Ihre persönliche Widmung an den Empfänger des Gutscheins (freilassen wenn Sie die Widmung persönlich per Hand eintragen möchten)."
              maxLength={240}
              maxLengthTemplate="Noch XXX Zeichen verfügbar"
            />
          </FieldSet>
          <FieldSet
            title="Abholung Gutschein im Restaurant"
            className={`${styles[`Shipping__fieldset`]} ${
              styles[`Shipping__fieldset--pickup`]
            } ${
              values['shippingMethod'] === ShippingMethod.PICKUP
                ? styles['Shipping__fieldset--active']
                : ''
            }`}
          >
            <div className={styles[`Shipping__pickup-dedication`]}>
              Natürlich besteht auch die Möglichkeit den Gutschein während
              unserer Öffnungszeiten im Restaurant abzuholen. Wir bereiten schon
              alles für Sie vor!
            </div>
            <TextAreaField
              label="Widmung"
              name="dedication"
              placeholder="Ihre persönliche Widmung an den Empfänger des Gutscheins (freilassen wenn Sie die Widmung persönlich per Hand eintragen möchten)."
              maxLength={240}
              maxLengthTemplate="Noch XXX Zeichen verfügbar"
            />
          </FieldSet>
        </div>
      </Container.Inner>
    </>
  );
};

export default Shipping;
