import React from 'react';
import { useFormState } from 'react-final-form';
import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js';
import IconRadioField from '@soosap/sushi/Form/IconRadioField';

import { PaymentMethod } from 'server/src/app/giftcard/types';
import CreditCardIcon from 'icons/CreditCard';
import BitcoinIcon from 'icons/Bitcoin';
import PayWithEuroIcon from 'icons/PayWithEuro';
import Container from 'atoms/Container';
import Title from 'atoms/Title';

import Cash from './components/Cash';
import CreditCard from './components/CreditCard';
import Bitcoin from './components/Bitcoin';
import styles from './Payment.module.scss';

export interface Props {
  setCreditCardNumberRef: (element: StripeCardNumberElement) => void;
  setCreditCardExpiryRef: (element: StripeCardExpiryElement) => void;
  setCreditCardCvcRef: (element: StripeCardCvcElement) => void;
}

const Payment: React.FC<Props> = ({
  setCreditCardNumberRef,
  setCreditCardExpiryRef,
  setCreditCardCvcRef,
}) => {
  const { values } = useFormState({ subscription: { values: true } });

  return (
    <>
      <Title>Bezahlung</Title>
      <Container.Inner>
        <div className={styles[`Payment`]}>
          <IconRadioField
            name="paymentMethod"
            className={styles[`Payment__methods`]}
          >
            <IconRadioField.Option
              id={PaymentMethod.CREDITCARD}
              svg={CreditCardIcon}
              title="mit Kreditkarte"
            />
            <IconRadioField.Option
              id={PaymentMethod.CASH}
              svg={PayWithEuroIcon}
              title="bei Abholung"
            />
            <IconRadioField.Option
              id={PaymentMethod.BITCOIN}
              svg={BitcoinIcon}
              disabled
              title="mit Bitcoin"
            />
          </IconRadioField>
          {values['paymentMethod'] === PaymentMethod.CREDITCARD ? (
            <CreditCard
              setCreditCardNumberRef={setCreditCardNumberRef}
              setCreditCardExpiryRef={setCreditCardExpiryRef}
              setCreditCardCvcRef={setCreditCardCvcRef}
            />
          ) : values['paymentMethod'] === PaymentMethod.CASH ? (
            <Cash />
          ) : (
            <Bitcoin />
          )}
        </div>
      </Container.Inner>
    </>
  );
};

export default Payment;
