import React, { useState } from 'react';
import axios from 'axios';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js';
import { FormApi } from 'final-form';
import Form from '@soosap/sushi/Form';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';

import { ShippingMethod, PaymentMethod } from 'server/src/app/giftcard/types';
import WarningIcon from 'icons/Warning';
import GiftCardIcon from 'icons/GiftCard';

import Selection from '../Selection';
import Payment from '../Payment';
import Shipping from '../Shipping';
import CallToAction from '../CallToAction';
import styles from './GiftCardForm.module.scss';

const { GATSBY_BACKEND_URL } = process.env;
const isClient = typeof window !== 'undefined';

export interface FormValues {
  nominalAmount: number;
  shippingMethod: ShippingMethod;
  paymentMethod: PaymentMethod;
  recipientFullName?: string;
  recipientEmail?: string;
  recipientAddress?: string;
  recipientPostcode?: string;
  recipientCity?: string;
  giverFullName?: string;
  giverPhoneNumber?: string;
  giverEqualsRecipient: boolean;
  dedication?: string;
  creditCard?: string;
  creditCardOwner?: string;
}

const initialValues: FormValues = {
  nominalAmount: 5000,
  paymentMethod: PaymentMethod.CREDITCARD,
  shippingMethod: ShippingMethod.EMAIL,
  giverEqualsRecipient: false,
};

export interface Props {}

const GiftCardForm: React.FC<Props> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useToasts();
  const [creditCardNumberRef, setCreditCardNumberRef] = useState<
    StripeCardNumberElement
  >();
  const [creditCardExpiryRef, setCreditCardExpiryRef] = useState<
    StripeCardExpiryElement
  >();
  const [creditCardCvcRef, setCreditCardCvcRef] = useState<
    StripeCardCvcElement
  >();

  const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
    let res;
    try {
      if (values.paymentMethod === PaymentMethod.CREDITCARD) {
        // a) stripe.js
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          throw new Error('stripe.js not loaded');
        }

        // b) cardElement
        const cardElement = elements.getElement(CardNumberElement);
        if (!cardElement) {
          throw new Error('No CardElement found');
        }

        // c) paymentMethod
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: values.creditCardOwner,
          },
        });

        if (!paymentMethod || error) {
          throw new Error(
            error ? error.message : 'No payment method was returned'
          );
        }

        res = await axios.post(`${GATSBY_BACKEND_URL}/api/giftcards`, {
          ...values,
          stripePaymentMethodId: paymentMethod.id,
        });
      } else {
        res = await axios.post(`${GATSBY_BACKEND_URL}/api/giftcards`, values);
      }

      addToast(res.data, {
        theme: ToastTheme.SUCCESS,
        autoDismiss: false,
        icon: GiftCardIcon,
      });

      // reset
      setTimeout(form.reset);

      if (values.shippingMethod === ShippingMethod.EMAIL) {
        form.resetFieldState('recipientFullName');
        form.resetFieldState('recipientEmail');
      } else if (values.shippingMethod === ShippingMethod.MAIL) {
        form.resetFieldState('recipientFullName');
      }

      if (values.paymentMethod === PaymentMethod.CREDITCARD) {
        form.resetFieldState('creditCardOwner');

        try {
          creditCardNumberRef && creditCardNumberRef.clear();
          creditCardExpiryRef && creditCardExpiryRef.clear();
          creditCardCvcRef && creditCardCvcRef.clear();
        } catch (e) {}
      }

      if (isClient) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'call-to-action',
          category: 'GiftCard',
          action: 'Purchase',
          value: values.nominalAmount / 100,
          label: `${(values.nominalAmount / 100).toFixed(2)} EUR`,
        });
      }
    } catch (e) {
      addToast(`Fehler! Bitte anrufen ☎ 07131 405 11 70`, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit as any} // TODO: Currently incorrectly typed in react-final-form
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        return (
          <form className={styles[`GiftCardForm`]} onSubmit={handleSubmit}>
            <Selection />
            <Shipping />
            <Payment
              setCreditCardNumberRef={setCreditCardNumberRef}
              setCreditCardExpiryRef={setCreditCardExpiryRef}
              setCreditCardCvcRef={setCreditCardCvcRef}
            />
            <CallToAction />
          </form>
        );
      }}
    />
  );
};

export default GiftCardForm;
