import React from 'react';

export interface Props {}

const PaymentBitcoin: React.FC<Props> = () => {
  return (
    <div className={`PaymentBitcoin`}>
      <span>TODO: PaymentBitcoin</span>
    </div>
  );
};

export default PaymentBitcoin;
