import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import Container from 'atoms/Container';
import GiftCardForm from './components/GiftCardForm/GiftCardForm';
import styles from './GiftCard.module.scss';

export interface Props {}

const GiftCard: React.FC<Props> = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    setStripePromise(
      loadStripe(String(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY))
    );
  }, []);

  return (
    <Container className={styles[`GiftCard`]}>
      <Elements stripe={stripePromise || null}>
        <GiftCardForm />
      </Elements>
    </Container>
  );
};

export default GiftCard;
