import React from 'react';
import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import CreditCardField from '@soosap/sushi/Form/CreditCardField';

import styles from '../Payment.module.scss';

export interface Props {
  setCreditCardNumberRef: (element: StripeCardNumberElement) => void;
  setCreditCardExpiryRef: (element: StripeCardExpiryElement) => void;
  setCreditCardCvcRef: (element: StripeCardCvcElement) => void;
}

const PaymentCreditCard: React.FC<Props> = ({
  setCreditCardNumberRef,
  setCreditCardExpiryRef,
  setCreditCardCvcRef,
}) => {
  return (
    <FieldSet
      className={styles[`Payment__fieldset`]}
      title="Bezahlung mit Kreditkarte"
    >
      <TextField
        name="creditCardOwner"
        label="Karteninhaber"
        placeholder="z.B. Dorothea Köster"
        required
        requiredError="Pflichtfeld"
      />
      <CreditCardField
        name="creditCard"
        label="Kartennummer"
        required
        requiredErrorNumber="Kartennummer ist ein Pflichtfeld"
        requiredErrorExpiry="Ablaufdatum ist ein Pflichtfeld"
        requiredErrorCvc="Prüfziffer ist ein Pflichtfeld"
        onNumberReady={setCreditCardNumberRef}
        onExpiryReady={setCreditCardExpiryRef}
        onCvcReady={setCreditCardCvcRef}
      />
    </FieldSet>
  );
};

export default PaymentCreditCard;
